import React, { useContext, useEffect, useState } from 'react';
import { DNAContext } from '../context/DNAContext';
import { useNavigate } from 'react-router-dom';
import classDefs from '../classes.json';
import styles from '../styles.json';

import { buildDna, buildSvg } from '../contract/contractMethods/svgMethods';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import chosen from '../assets/images/chosen.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Thumbs, Controller } from 'swiper';
import { StepperContext } from '../context/StepperContext';
import StepperContainer from '../components/StepperContainer';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import { Stepper } from 'react-form-stepper';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Thumbs, Controller]);

const MotivumPicker = () => {
  const dnaCtx = useContext(DNAContext);
  let currentDna = dnaCtx.dna;
  //console.log(currentDna, "latest dna");

  const nrOfDivisons = dnaCtx.nrOfDivisions;
  //console.log(nrOfDivisons, "nr of divisions");

  const [latestDna, setLatestDna] = useState(currentDna);
  const [colors, setColors] = useState(null);
  const [motifs, setMotifs] = useState(null);
  const [finalSvgArray, setFinalSvgArray] = useState(null);
  const [fiteredMotvesArray, setfiteredMotvesArray] = useState(null);
  const [selectedMotive, setselectedMotive] = useState(null);
  const [isFinalized, setisFinalized] = useState(false);
  const [qr, setQr] = useState(null);
  const [finalSvg, setFinalSvg] = useState(null);
  const [currentWindowWidth, setCurrentWindowWidth] = useState(
    window.outerWidth
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setCurrentStep } = useContext(StepperContext);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const [subclassId, setsubclassId] = useState(null);

  const [swiper, setSwiper] = useState(null);
  const [kiosk, setKiosk] = useState(null);

  function isTouchDevice() {
    return (
      ('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0) &&
      window.outerWidth > 1904
    );
  }

  useEffect(() => {
    document.getElementsByClassName('header')[0].style.display = 'flex';
    document.getElementById('RFS-StepperContainer').style.display = 'flex';

    const isKiosk = isTouchDevice();
    setKiosk(isKiosk);

    const svgFromLocalStorage = localStorage.getItem('final-svg');
    const classidFromLocalStorage = localStorage.getItem(
      'selected-motive-class'
    );
    if (svgFromLocalStorage && classidFromLocalStorage) {
      setFinalSvg(svgFromLocalStorage);
      setsubclassId(classidFromLocalStorage);
    }

    const colors = Object.values(styles).filter((v) => v.type === 'fill');
    setColors(colors);
    const filteredArray = Object.values(classDefs).filter(
      (v) => v.className === 'motivumok'
    );
    setMotifs(filteredArray);
  }, []);

  useEffect(() => {
    let builtDnaArray = [];

    if (motifs) {
      motifs.map((motif) => {
        let builtDna;
        const blackObj = colors.find((color) => color.name === 'black');
        switch (nrOfDivisons) {
          case 2:
            builtDna = buildDna([motif], [blackObj], latestDna, 9);
            break;
          case 3:
            builtDna = buildDna([motif], [blackObj], latestDna, 12);
            break;
          case 4:
            builtDna = buildDna([motif], [blackObj], latestDna, 15);
            break;
          case 5:
            builtDna = buildDna([motif], [blackObj], latestDna, 18);
            break;
          default:
            builtDna = buildDna([motif], [blackObj], latestDna, 9);
            break;
        }
        builtDnaArray.push(builtDna);
        return buildDna;
      });

      const fetch = async () => {
        let svgArray = [];
        for (let i = 0; i < builtDnaArray.length; i++) {
          const svg = await buildSvg(builtDnaArray[i].dna);
          const svgObj = {
            dna: builtDnaArray[i].dna,
            svg: svg,
            nrOfDivisions: builtDnaArray[i].nrOfDivisions,
            classId: builtDnaArray[i].classId,
            subclassId: builtDnaArray[i].subclassId,
          };
          svgArray.push(svgObj);
        }
        console.log(svgArray + 'SVG ARRAY');
        setFinalSvgArray(svgArray);
        return svgArray;
      };
      fetch();
    }
  }, [motifs, latestDna, nrOfDivisons, colors]);

  useEffect(() => {
    if (selectedMotive) {
      dnaCtx.setDNA(selectedMotive.dna);
      setFinalSvg(selectedMotive.shape);
      localStorage.setItem('final-svg', selectedMotive.shape);
      localStorage.setItem('selected-motive-class', selectedMotive.subclassId);
    }
  }, [selectedMotive]);

  const goToQR = () => {
    navigate('/qr');
  };

  const finalize = () => {
    setisFinalized(true);
  };

  const unsetFinalized = () => {
    setisFinalized(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
  }, []);

  const goBackToColorpicker = () => {
    localStorage.removeItem('selected-motive-class');
    dnaCtx.setDNA(latestDna);
    navigate('/colorpicker');
  };

  const goToMint = () => {
    navigate('/mint');
  };

  useEffect(() => {
    if (localStorage.getItem('selected-motive-class')) {
      if (swiper) {
        const timer = setTimeout(() => {
          const slideNr = localStorage.getItem('selected-motive-class');
          console.log(slideNr, 'slide nr');
          swiper.slideTo(slideNr - 1);
        }, 100);
        return () => clearTimeout(timer);
      }
    }
  }, [swiper]);

  console.log(selectedMotive, 'selected motive');
  console.log(kiosk, 'kiosk');
  console.log(dnaCtx.dna, 'final dna')

  return (
    <div className="motives">
      <h2 className="page-title-pajzsok">{t('title')}</h2>

      {/* <Stepper
        steps={[{ }, { }, { }, { }]}
        activeStep={0}
      /> */}

      <p className="screens-text">{t('motiveText')}</p>
      <div className="motive-shapes">
        {currentWindowWidth <= 992 && (
          <Swiper
            id="thumbs"
            spaceBetween={window.outerWidth >= 425 ? -80 : -40}
            slidesPerView={1}
            loop={true}
            onSwiper={(s) => {
              console.log('initialize swiper', s);
              setSwiper(s);
            }}
          >
            {finalSvgArray &&
              finalSvgArray.map((svg, index) => {
                return (
                  <SwiperSlide key={svg.subclassId}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: svg.svg,
                      }}
                      key={svg.svg}
                      style={{
                        width: '305px',
                        height: '305px',
                        margin: '0 auto',
                        border:
                          (selectedMotive &&
                            svg.svg === selectedMotive.shape) ||
                          (localStorage.getItem('final-svg') &&
                            svg.svg === localStorage.getItem('final-svg'))
                            ? '1px solid #BA5B2D'
                            : '1px solid #00000033',
                      }}
                      onClick={() =>
                        setselectedMotive({
                          shape: svg.svg,
                          dna: svg.dna,
                          nrOfDivisions: svg.nrOfDivisions,
                          classId: svg.classId,
                          subclassId: svg.subclassId,
                        })
                      }
                    ></div>
                    {(selectedMotive && svg.svg === selectedMotive.shape) ||
                      (localStorage.getItem('final-svg') &&
                        svg.svg === localStorage.getItem('final-svg') && (
                          <img src={chosen} className="chosen-tag"></img>
                        ))}
                    <div className="slide-nr">
                      {index + 1}/{finalSvgArray.length}
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        )}

        {currentWindowWidth > 992 && (
          <>
            {finalSvgArray &&
              finalSvgArray.map((svg, index) => {
                return (
                  <div className="element" key={index}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: svg.svg,
                      }}
                      key={svg.svg}
                      style={{
                        width: '305px',
                        height: '305px',
                        margin: '0 auto',
                        border:
                          (selectedMotive &&
                            svg.svg === selectedMotive.shape) ||
                          (localStorage.getItem('final-svg') &&
                            svg.svg === localStorage.getItem('final-svg'))
                            ? '1px solid #BA5B2D'
                            : '1px solid #00000033',
                      }}
                      onClick={() =>
                        setselectedMotive({
                          shape: svg.svg,
                          dna: svg.dna,
                          nrOfDivisions: svg.nrOfDivisions,
                          classId: svg.classId,
                          subclassId: svg.subclassId,
                        })
                      }
                    ></div>
                    {(selectedMotive && svg.svg === selectedMotive.shape) ||
                      (localStorage.getItem('final-svg') &&
                        svg.svg === localStorage.getItem('final-svg') && (
                          <img src={chosen} className="chosen-tag"></img>
                        ))}
                    <div className="slide-nr">
                      {index + 1}/{finalSvgArray.length}
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>

      <div className="holder">
        <div className="langpage-footer">
          <Footer />
        </div>

        <div className="sticky-button-holder">
          <button
            className="select-button"
            onClick={!isFinalized ? finalize : !kiosk ? goToQR : goToMint}
            disabled={!isFinalized && !selectedMotive}
          >
            {!isFinalized ? 'címer véglegesítése' : 'qr kód megnyitása'}
          </button>

          <button
            className="back-button"
            onClick={() => {
              goBackToColorpicker();
              setCurrentStep(2);
            }}
          >
            vissza
          </button>
        </div>
      </div>
    </div>
  );
};

export default MotivumPicker;
