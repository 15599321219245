import './App.css';
import React, { useEffect, useState, useContext } from 'react';
import {
  getClassCount,
  getSubclassCountByClassId,
  getSvgBytesForSubclasses,
  getSvgBytesByClassId,
  getStyleCount,
  getStyles,
} from './contract/contractMethods/contractMethods';
import transformIcon from './assets/images/transform.png';
import { Routes, Route, HashRouter, BrowserRouter } from 'react-router-dom';
import routes from './routes/Routes';
import DNAContextProvider from './context/DNAContext';
import StepperContextProvider from './context/StepperContext';
import { main } from './contract/contractMethods/svgMethods';
import StepperContainer from './components/StepperContainer';
import Header from './components/Header';
import { DNAContext } from './context/DNAContext';

function App() {

const ctx = useContext(DNAContext);

  function isTouchDevice() {
    return (
      (("ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0) &&
        window.outerWidth > 1904)
    );
  }

  useEffect(() => {
    const isKiosk = isTouchDevice();
    console.log(isKiosk, "is kiosk");
    if (isKiosk) {
      console.log('kioskot beallitja az app.js-ben')
      ctx.setIsKiosk(true);
    }
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);


  console.log(ctx.isKiosk, 'is kiosk ctx');

  return (
    <StepperContextProvider>
      <DNAContextProvider>
        <HashRouter basename="/">
          <Header />
          <StepperContainer />
          <Routes>
            {routes.map(({ path, element, exact }) => (
              <Route key={path} path={path} exact={exact} element={element} />
            ))}
          </Routes>
        </HashRouter>
      </DNAContextProvider>
    </StepperContextProvider>
  );
}

export default App;
