import Formak from "../views/Formak";
import LanguagePage from "../views/LanguagePage";
import Home from "../views/Home";
import Pajzsformak from "../views/Pajzsformak";
import Colorpicker from "../views/Colorpicker";
import MotivumPicker from "../views/MotivumPicker";
import Scanner from "../views/Scanner";
import QRViewer from "../views/QRViewer";
import Mint from "../views/Mint";
import Crest from "../views/Crest";

const routes = [
  {
    path: "/",
    element: <LanguagePage />,
    exact: true,
  },
  {
    path: "/home",
    element: <Home />,
    exact: true,
  },
  {
    path: "/formak",
    element: <Formak />,
    exact: true,
  },
  {
    path: "/pajzs",
    element: <Pajzsformak />,
    exact: true,
  },
  {
    path: "/scanner",
    element: <Scanner />,
    exact: true,
  },
  {
    path: "/colorpicker",
    element: <Colorpicker />,
    exact: true,
  },
  {
    path: "/motivum",
    element: <MotivumPicker />,
    exact: true,
  },
  {
    path: "/qr",
    element: <QRViewer />,
    exact: true,
  },
  {
    path: "/mint",
    element: <Mint />,
    exact: true,
  },
  {
    path: "/crest",
    element: <Crest />,
    exact: true,
  },
];

export default routes;
