import * as ethers from "ethers";
import abi from "../cimer-abi.json";

const cimerContract = "0x3867F9DE528579D5cd6717E779eabAfBDFb96C43";
const rpc =
  "https://polygon-mumbai.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc";

let provider;
export const rpcProvider = () => {
  if (provider === undefined) {
    return (provider = new ethers.providers.JsonRpcProvider(rpc));
  } else return provider;
};

const contract = new ethers.Contract(cimerContract, abi.abi, rpcProvider());

export const getBalance = async (address) => {
  const balanceOfUser = await contract.balanceOf(address).then().catch();
  //console.log(balanceOfUser, "balance of");
  const balanceOf = balanceOfUser.toNumber();
  return balanceOf;
};

export const getTokenOfOwner = async (owner, index) => {
  const tokenOfOwner = await contract
    .tokenOfOwnerByIndex(owner, index)
    .then()
    .catch();
  //console.log(tokenOfOwner, "token of owner");
  return tokenOfOwner;
};

export const getTokenUri = async (tokenIndex) => {
  const tokenUri = await contract.tokenURI(tokenIndex).then().catch();
  return tokenUri;
};

export const getTokenDna = async (tokenDna) => {
  const dna = await contract.tokenDna(tokenDna).then().catch();
  console.log(dna, "dna");
  return dna;
};
