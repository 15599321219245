import React, { useState, useContext, useEffect } from "react";
import { DNAContext } from "../context/DNAContext";
import axios from "axios";
import CryptoJS from "crypto-js";
import config from "../config.json";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router";

const Mint = () => {
  const dnaCtx = useContext(DNAContext);
  const [dna, setDna] = useState(dnaCtx.dna);
  const [address, setAddress] = useState(dnaCtx.scannedAddress);
  //   const [kiosk, setKiosk] = useState(false)

  const [errorText, setErrorText] = useState("");
  const [mintSuccess, setMintSuccess] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    document.getElementsByClassName("header")[0].style.display = "none";
    document.getElementById("RFS-StepperContainer").style.display = "none";
  }, []);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const password = process.env.REACT_APP_PASSWORD;

  useEffect(() => {
    const Mint = async () => {
      const dna = dnaCtx.dna;
      const address = dnaCtx.scannedAddress;
      const crestPhraseToHash = dna + ":" + password + ":" + address;
      const crestHash = CryptoJS.MD5(crestPhraseToHash).toString();

      setLoading(true);

      await axios
        .post(backendUrl + "mintCrest", {
          dna: dna,
          walletAddress: dnaCtx.scannedAddress,
          md5Hash: crestHash,
        })
        .then((response) => {
          console.log(response, "resp");
          let respStatus = response.status;
          if (respStatus !== 200) {
            setMintSuccess(false);
            setErrorText(response.data.message, "resp");
          } else {
            setMintSuccess(true);
          }
        })
        .catch((e) => {
          console.log(e, " error");
          setMintSuccess(false);
          setErrorText(e.response.data.message);
        });

      setLoading(false);
    };
    Mint();
  }, []);

  // useEffect(() => {
  //   if (mintSuccess === null) return;
  //   if (mintSuccess) {
  //     setTimeout(() => {
  //       navigate("/home");
  //     }, 5000);
  //   } else {
  //     setTimeout(() => {
  //       navigate("/home");
  //     }, 5000);
  //   }
  // }, [mintSuccess]);

  console.log(errorText)

  return (
    <>
      {!isLoading ? (
        <div
          className={`mint-feedback-container  ${
            !mintSuccess ? "error-inner-texts" : ""
          }`}
        >
          <div className="mint-feedback-inner-texts">
            {mintSuccess === true ? (
              <>
                <div className="feedback-msg">
                  <p
                    className="success-mint"
                    style={{ fontFamily: config.MUZEUM.titleFont }}
                  >
                    SIKER!
                  </p>
                  <p
                    className="mint-message"
                    style={{
                      color: config.MUZEUM.textColor,
                      fontFamily: config.MUZEUM.font,
                    }}
                  >
                    Gratulálunk, sikeresen létrejött az alábbi digitális
                    élménykártya:
                  </p>
                  <div className="minted">
                    <div>cimer helye</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {mintSuccess !== null && (
                  <div className="feedback-msg">
                    <p
                      className="error-mint"
                      style={{ fontFamily: config.MUZEUM.titleFont }}
                    >
                      HIBA!
                    </p>
                    <p
                      className="mint-message"
                      style={{
                        color: config.MUZEUM.textColor,
                        fontFamily: config.MUZEUM.font,
                      }}
                    >
                      {errorText === "You need:2 more tokens" ? "Még nem gyűjtöttél ki elég élményt a címer megszerzéséhez" : ""}
                      {/* {errorText.response.data.error.includes(
                        "Address already owns a token"
                      )
                        ? "A kártya megszerzése sikertelen volt, mivel ez a kártya már megtalálható a beolvasott címen."
                        : errorText.message} */}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="loader">
          <ClipLoader
            color={"#999999"}
            loading={isLoading}
            size={140}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default Mint;
