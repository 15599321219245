import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import config from '../config.json';
import { DNAContext } from '../context/DNAContext';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
  const [manualAddressInput, setManualAddressInput] = useState(false);
  const [address, setAddress] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const navigate = useNavigate();

  const dnaCtx = useContext(DNAContext);

  const { t } = useTranslation();

  const navigatToQRScan = () => {
    navigate('/scanner');
  };
  const handleManualAddress = () => {
    setManualAddressInput(true);
  };
  const showNFTs = () => {
    let first2;
    if (address !== null) {
      first2 = address.slice(0, 2);
    }
    if (first2 === '0x' && address.length > 25) {
      navigate('/pajzs?address=' + address);
    } else {
      setAddressError('Invalid address');
    }
  };

  useEffect(() => {
    let first2;
    if (address !== null) {
      first2 = address.slice(0, 2);
    }
    if (first2 === '0x' && address.length == 42) {
      navigate('/pajzs?address=' + address);
    } else {
      setAddressError('Invalid address');
    }
  }, [address]);

  const handleInputChange = (event) => {
    const address = event.target.value;
    setAddress(address);
    dnaCtx.setScannedAddress(address);
    //navigate("/wallet?address=" +  address);
  };

  
  useEffect(() => {
    document.getElementsByClassName("header")[0].style.display = "flex";
  }, []);


  return (
    <>
      <div className="lb-home">
        <div className="lb-content-section">
          <h2
            className="page-title-home"
            style={{
              color: config.MUZEUM.titleColor,
              fontFamily: config.MUZEUM.titleFont,
            }}
          >
            {t('title')}
          </h2>
          <div
            className="home-texts"
            style={{
              color: config.MUZEUM.textColor,
              fontFamily: config.MUZEUM.font,
            }}
          >
            {t('welcomeText')}
          </div>
          <div className="scan-button">
            <p
              style={{
                color: config.MUZEUM.textColor,
                fontFamily: config.MUZEUM.font,
              }}
            >
              {' '}
              {t('scanText')}
            </p>
            <button
              className="gotoscan-button uppercase"
              onClick={navigatToQRScan}
              style={{
                backgroundColor: config.MUZEUM.textColor,
                borderColor: config.MUZEUM.textColor,
                fontFamily: config.MUZEUM.titleFont,
              }}
            >
              {t('scanButtonText')}
            </button>
          </div>

          <div className="manual-input">
            <p
              style={{
                color: config.MUZEUM.textColor,
                fontFamily: config.MUZEUM.font,
              }}
            >
              {t('addressInputText')}
            </p>
            <input
              className="address-input"
              onChange={handleInputChange}
              type="text"
              style={{
                color: config.MUZEUM.textColor,
                fontFamily: config.MUZEUM.font,
                borderColor: '#5A513F4D',
              }}
              placeholder={t('addressInputPlaceholder')}
            />
          </div>
        </div>
        <div className="langpage-footer" style={{ bottom: '24px' }}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
