import React, { useContext, useEffect, useState } from 'react';
import { DNAContext } from '../context/DNAContext';
import { useNavigate } from 'react-router-dom';
import classDefs from '../classes.json';
import styles from '../styles.json';
import { buildDna, buildSvg } from '../contract/contractMethods/svgMethods';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import chosen from '../assets/images/chosen.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Thumbs, Controller } from 'swiper';
import { StepperContext } from '../context/StepperContext';
import StepperContainer from '../components/StepperContainer';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Thumbs, Controller]);

const Formak = () => {
  const [selectedShape, setselectedShape] = useState(null);
  const [finalSvgArray, setFinalSvgArray] = useState(null);
  const [backgrounds, setBackgrounds] = useState(null);
  const [currentWindowWidth, setCurrentWindowWidth] = useState(
    window.outerWidth
  );
  const [colors, setColors] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setCurrentStep } = useContext(StepperContext);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const [swiper, setSwiper] = useState(null);

  const dnaCtx = useContext(DNAContext);
  let currentDna = dnaCtx.dna;

  useEffect(() => {
    const chosenSvg = localStorage.getItem('forma-svg');
    const chosenClass = localStorage.getItem('forma-class');
    const chosenSubclass = localStorage.getItem('forma-subclass');
    const chosenDna = localStorage.getItem('forma-dna');
    const chosennrOfDivisions = localStorage.getItem('forma-nrOfDivisions');
    //console.log(chosenSvg, 'pajzs-svg')
    if (
      chosenSvg &&
      chosenClass &&
      chosenSubclass &&
      chosenDna &&
      chosennrOfDivisions
    ) {
      setselectedShape({
        shape: chosenSvg,
        dna: dnaCtx.dna,
        nrOfDivisions: chosennrOfDivisions,
        classId: chosenClass,
        subclassId: chosenSubclass,
      });

      dnaCtx.setDNA(dnaCtx.dna);
      dnaCtx.setNrOfDivisions(dnaCtx.nrOfDivisions);
      dnaCtx.setSelectedShape({
        shape: chosenSvg,
        dna: dnaCtx.dna,
        nrOfDivisions: chosennrOfDivisions,
        classId: chosenClass,
        subclassId: chosenSubclass,
      });
    }

    // 1-es kategoria a legit szinek, 2-es a frontend
    const colors = Object.values(styles).filter(
      (v) => v.type === 'fill' && v.category === '1'
    );
    const classes = Object.values(classDefs).filter((v) =>
      v.className.startsWith('hatter-')
    );
    const splitClasses = new Map();
    classes.forEach((c) => {
      if (splitClasses.has(c.classId)) {
        splitClasses.set(c.classId, [...splitClasses.get(c.classId), c]);
      } else {
        splitClasses.set(c.classId, [c]);
      }
    });
    console.log(splitClasses, 'lolllz');
    setBackgrounds(splitClasses);
    setColors(colors);
  }, []);

  useEffect(() => {
    let builtDnaArray = [];

    if (backgrounds) {
      // 1-es kategoria a legit szinek, 2-es a frontend
      const frontEndColors = Object.values(styles).filter(
        (v) => v.type === 'fill' && v.category === '2'
  
      );
      console.log(frontEndColors, 'fe collolrs')
      for (let background of backgrounds.values()) {
        const newDna = buildDna(background, frontEndColors, currentDna, 3);
        builtDnaArray.push(newDna);
      }

      const fetch = async () => {
        //console.log(builtDnaArray, "built arrayyyyyyyy");
        let svgArray = [];
        for (let i = 0; i < builtDnaArray.length; i++) {
          const svg = await buildSvg(builtDnaArray[i].dna);
          const svgObj = {
            dna: builtDnaArray[i].dna,
            svg: svg,
            nrOfDivisions: builtDnaArray[i].nrOfDivisions,
            classId: builtDnaArray[i].classId,
            subclassId: builtDnaArray[i].subclassId,
          };
          svgArray.push(svgObj);
        }
        //console.log(svgArray, "final svg array");
        setFinalSvgArray(svgArray);
        return svgArray;
      };
      fetch();
    }
  }, [backgrounds]);

  useEffect(() => {
    if (selectedShape) {
      dnaCtx.setDNA(selectedShape.dna);
      dnaCtx.setNrOfDivisions(selectedShape.nrOfDivisions);
      dnaCtx.setSelectedShape(selectedShape);

      //console.log(selectedShape.dna , 'selected shape dna')

      localStorage.setItem('forma-svg', selectedShape.shape);
      localStorage.setItem('forma-class', selectedShape.classId);
      localStorage.setItem('forma-subclass', selectedShape.subclassId);
      localStorage.setItem('forma-dna', selectedShape.dna);
      localStorage.setItem('forma-nrOfDivisions', selectedShape.nrOfDivisions);
    }
  }, [selectedShape, dnaCtx]);

  const goToColorpicker = () => {
    if (selectedShape) {
      navigate('/colorpicker');
    }
  };

  const goBackToPajzsformak = () => {
    navigate('/pajzs');
  };

  useEffect(() => {
    if (selectedShape) {
      if (swiper) {
        const timer = setTimeout(() => {
          swiper.slideTo(selectedShape.classId - 1);
        }, 100);
        return () => clearTimeout(timer);
      }
    }
  }, [swiper]);

  console.log(selectedShape, 'selected forma');

  return (
    <div className="App">
      {/* <Header /> */}
      <h2 className="page-title-pajzsok">{t("title")}</h2>

      {/* <Stepper
        steps={[{ }, { }, { }, { }]}
        activeStep={0}
      /> */}

      <p className="screens-text">{t("divisionText")}</p>
      <div className="shapes">
        {currentWindowWidth <= 992 && (
          <Swiper
            id="thumbs"
            spaceBetween={window.outerWidth >= 425 ? -80 : -40}
            slidesPerView={1}
            loop={true}
            onSwiper={(s) => {
              console.log('initialize swiper', s);
              setSwiper(s);
            }}
          >
            {finalSvgArray &&
              finalSvgArray.map((svg, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className="wrapper"
                      style={{
                        border:
                          selectedShape && svg.svg === selectedShape.shape
                            ? '1px solid #BA5B2D'
                            : '1px solid #00000033',
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: svg.svg,
                        }}
                        key={svg.svg}
                        style={{
                          width: '305px',
                          height: '305px',
                          margin: '0 auto',
                          //filter: "grayscale(100%)",
                          // border:
                          // selectedShape && svg.svg === selectedShape.shape
                          //   ? "1px solid #BA5B2D"
                          //   : "1px solid #00000033",
                        }}
                        onClick={() =>
                          setselectedShape({
                            shape: svg.svg,
                            dna: svg.dna,
                            nrOfDivisions: svg.nrOfDivisions,
                            classId: svg.classId,
                            subclassId: svg.subclassId,
                          })
                        }
                      ></div>
                    </div>
                    {selectedShape && svg.svg === selectedShape.shape && (
                      <img src={chosen} className="chosen-tag"></img>
                    )}
                    <div className="slide-nr">
                      {index + 1}/{finalSvgArray.length}
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        )}
        {currentWindowWidth > 992 && (
          <>
            {finalSvgArray &&
              finalSvgArray.map((svg, index) => {
                return (
                  <div className="element" key={index}>
                    <div
                      className="wrapper"
                      style={{
                        border:
                          selectedShape && svg.svg === selectedShape.shape
                            ? '1px solid #BA5B2D'
                            : '1px solid #00000033',
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: svg.svg,
                        }}
                        key={svg.svg}
                        style={{
                          width: '305px',
                          height: '305px',
                          margin: '0 auto',
                          //filter: "grayscale(100%)",
                          // border:
                          // selectedShape && svg.svg === selectedShape.shape
                          //   ? "1px solid #BA5B2D"
                          //   : "1px solid #00000033",
                        }}
                        onClick={() =>
                          setselectedShape({
                            shape: svg.svg,
                            dna: svg.dna,
                            nrOfDivisions: svg.nrOfDivisions,
                            classId: svg.classId,
                            subclassId: svg.subclassId,
                          })
                        }
                      ></div>
                    </div>
                    {selectedShape && svg.svg === selectedShape.shape && (
                      <img src={chosen} className="chosen-tag"></img>
                    )}
                    <div className="slide-nr">
                      {index + 1}/{finalSvgArray.length}
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>

      <div className="holder">
        <div className="langpage-footer">
          <Footer />
        </div>

        <div className="sticky-button-holder">
          <button
            className="select-button"
            onClick={() => {
              goToColorpicker();
              setCurrentStep(2);
            }}
            disabled={!selectedShape}
          >
            kiválasztom
          </button>

          <button
            className="back-button"
            onClick={() => {
              goBackToPajzsformak();
              setCurrentStep(0);
            }}
          >
            vissza
          </button>
        </div>
      </div>
    </div>
  );
};

export default Formak;
