import React, { useState, useEffect, useContext } from "react";
import QrReader from "react-qr-reader";
import "./QRScanner.css";
import { useNavigate } from "react-router-dom";
import closeBtn from "../assets/images/x-circle.png";
import { DNAContext } from "../context/DNAContext";

const QrScanner = ({ setWalletAddress }) => {
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const sourceCtx = useContext(DNAContext);


  // const handleScan = (scanData) => {
  //   if (scanData && scanData) {
  //     //console.log(scanData, 'scanned data')
  //     const regexForUrl = /^((.*\?.*address=)|(ether:))?(0x[a-fA-F0-9]{40})/;
  //     const checkedUrl = scanData.match(regexForUrl)[0];
  //     //console.log(checkedUrl, 'checked url')
  //     const regexForAddress = /0x[a-fA-F0-9]{40}/
  //     const extractedAddress = checkedUrl.match(regexForAddress)[0];
  //     //console.log(extractedAddress, 'extracetd address')

  //     const address = extractedAddress.toLowerCase();
  //     setWalletAddress(address);
  //     sourceCtx.setScannedAddress(address);
  //     navigate(`/pajzs?address=${address}`);
  //   }
  // };

  const handleScan = (scanData) => {
    if (scanData && scanData) {
      console.log(scanData, "scandata");
      try {
        const scannedObj = JSON.parse(scanData);
        //console.log(scannedObj, "scanned obj");
        console.log("obj van");

        const address = scannedObj.address;
        const dna = scannedObj.dna;

        console.log(address, "address");
        console.log(dna, "dna");

        setWalletAddress(address);
        sourceCtx.setScannedAddress(address);
        sourceCtx.setDNA(dna);
        navigate("/crest");
      } catch (err) {
        console.log("link van");

        const regexForUrl = /(ethereum:|.*\?.*address=)?(0x[a-fA-F0-9]{40})/;
        const checkedUrl = scanData.match(regexForUrl)[0];
        const regexForAddress = /0x[a-fA-F0-9]{40}/;
        const extractedAddress = checkedUrl.match(regexForAddress)[0];
        //console.log(extractedAddress, "eextracted address");

        const address = extractedAddress.toLowerCase();
        //console.log(address, 'addresss')
        setWalletAddress(address);
        sourceCtx.setScannedAddress(address);
        navigate(`/pajzs?address=${address}`);
      }
    }
  };

  const handleNavigate = () => {
    navigate("/home");
  };

  const onError = (err) => {
    console.log("scanner error");
    console.error(err);
  };

  
  useEffect(() => {
    document.getElementsByClassName("header")[0].style.display = "none";
    const stepper = document.getElementById("RFS-StepperContainer").style.display = "none";
  }, []);


  return (
    <>
      <div className="scanner-holder">
        <button className="qr-close-btn" onClick={handleNavigate}>
          <img src={closeBtn} />
        </button>
        {!showDialog && !processing && (
          <QrReader
            delay={500}
            onScan={handleScan}
            constraints={{
              facingMode: "environment",
            }}
            className="qr-reader"
            onError={onError}
          />
        )}
      </div>
    </>
  );
};

export default QrScanner;
